// IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}

// Hide loading after rendering page
export const onInitialClientRender = async () => {
  setTimeout(function () {
    document.getElementById("___loader").style.opacity = "0"
    setTimeout(function () {
      document.getElementById("___loader").style.display = "none"
    }, 500)
  }, 1000)
}
